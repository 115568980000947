import { css } from 'styled-components';
import { size } from './size';

export const squareSize = (size: string) => css`
  width: ${size};
  height: ${size};
`;

export const customMinMediaQuery = (minWidth: number): string =>
  `@media (min-width: ${minWidth}px)`;

export const minWidth = {
  desktop: customMinMediaQuery(size.breakpoints.lg),
  tablet: customMinMediaQuery(size.breakpoints.md),
  mobile: customMinMediaQuery(size.breakpoints.sm),
  custom: (width: number): string => customMinMediaQuery(width),
};
