import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const FoldersIcon: FC<IconSVGProps> = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 32 32"
  >
    <defs>
      <path id="path-1" d="M1.22583916e-15 0L32 0 32 32 0 32z" />
      <path
        id="path-322"
        d="M27.641 22.805a2.087 2.087 0 01-2.085 2.084H5.585A2.087 2.087 0 013.5 22.805V9.584c0-1.149.936-2.084 2.085-2.084h5.229c.49 0 1.11.351 1.294 1.002.069.247.151.517.23.771h-2.261a.75.75 0 000 1.5H25.556c1.149 0 2.085.936 2.085 2.085v9.947zM25.556 9.273H13.912a36.833 36.833 0 01-.361-1.177C13.209 6.881 12.058 6 10.814 6H5.585A3.59 3.59 0 002 9.584v13.221a3.589 3.589 0 003.585 3.584h19.971a3.588 3.588 0 003.585-3.584v-9.947a3.589 3.589 0 00-3.585-3.585z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <mask fill="currentColor">
        <use xlinkHref="#path-wew1" />
      </mask>
      <mask fill="currentColor">
        <use xlinkHref="#path-322" />
      </mask>
      <use fill="currentColor" xlinkHref="#path-322" />
    </g>
  </svg>
);
