import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { useUserData } from '../../../hooks/useUserData';
import { UserProfileSection } from './UserProfileSection';
import { Navigation } from './Navigation';
import { ProjectLogo } from './ProjectLogo';
import Link from 'next/link';
import { Routes } from '../../../enums/routes';

export const Sidebar: FC = () => {
  const { user } = useUserData();

  return (
    <SideBarContainer>
      <Link href={Routes.Dashboard}>
        <LogoWrapper>
          <ProjectLogo />
        </LogoWrapper>
      </Link>

      {user?.profile && user?.onboardingData && <UserProfileSection />}

      <Navigation />
    </SideBarContainer>
  );
};

const SideBarContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 36px 20px;
  max-width: 270px;
  background-color: ${colors.brand};
  overflow: scroll;
  color: white;
  position: fixed;
`;

const LogoWrapper = styled.div`
  margin-bottom: 40px;
  cursor: pointer;
`;
