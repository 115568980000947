import { FC } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
};

export const SBXLogo: FC<Props> = ({ className }) => {
  return (
    <StyledLogo src="/SBXLogo.png" alt="StartupBox" className={className} />
  );
};

const StyledLogo = styled.img`
  height: 20px;
`;
