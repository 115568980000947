import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const OpenNew: FC<IconSVGProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4086 6.34239V26.3288C6.4086 26.422 6.38865 26.5107 6.3526 26.5914L25.6576 26.5905V19.7043C25.6576 19.3155 25.9592 19 26.3288 19C26.6993 19 27 19.3155 27 19.7043V27.2948C27 27.6835 26.6993 27.9991 26.3288 27.9991L5.6712 28C5.3007 28 5 27.6845 5 27.2957C5 27.1003 5.07596 26.9234 5.19856 26.7958C5.07567 26.6749 5 26.5102 5 26.3288V5.6712C5 5.3007 5.31553 5 5.7043 5H13.2957C13.6845 5 14 5.3007 14 5.6712C14 6.0417 13.6845 6.34239 13.2957 6.34239H6.4086ZM19.5131 13.6626L25.2254 7.95537V13.1074C25.2254 13.601 25.6229 14 26.1127 14C26.6034 14 27 13.601 27 13.1074V5.89259C27 5.39899 26.6034 5 26.1127 5H18.9409C18.4511 5 18.0536 5.39899 18.0536 5.89259C18.0536 6.38619 18.4511 6.78518 18.9409 6.78518H23.8777L18.2621 12.3951C17.9143 12.7432 17.9125 13.3082 18.2576 13.6581C18.4316 13.8331 18.6596 13.9215 18.8876 13.9215C19.1139 13.9215 19.3401 13.8349 19.5131 13.6626Z"
      fill="#414042"
    />
    <mask
      id="mask0_0_29911"
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="22"
      height="23"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4086 6.34239V26.3288C6.4086 26.422 6.38865 26.5107 6.3526 26.5914L25.6576 26.5905V19.7043C25.6576 19.3155 25.9592 19 26.3288 19C26.6993 19 27 19.3155 27 19.7043V27.2948C27 27.6835 26.6993 27.9991 26.3288 27.9991L5.6712 28C5.3007 28 5 27.6845 5 27.2957C5 27.1003 5.07596 26.9234 5.19856 26.7958C5.07567 26.6749 5 26.5102 5 26.3288V5.6712C5 5.3007 5.31553 5 5.7043 5H13.2957C13.6845 5 14 5.3007 14 5.6712C14 6.0417 13.6845 6.34239 13.2957 6.34239H6.4086ZM19.5131 13.6626L25.2254 7.95537V13.1074C25.2254 13.601 25.6229 14 26.1127 14C26.6034 14 27 13.601 27 13.1074V5.89259C27 5.39899 26.6034 5 26.1127 5H18.9409C18.4511 5 18.0536 5.39899 18.0536 5.89259C18.0536 6.38619 18.4511 6.78518 18.9409 6.78518H23.8777L18.2621 12.3951C17.9143 12.7432 17.9125 13.3082 18.2576 13.6581C18.4316 13.8331 18.6596 13.9215 18.8876 13.9215C19.1139 13.9215 19.3401 13.8349 19.5131 13.6626Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_0_29911)">
      <rect width="32" height="32" fill="white" />
    </g>
  </svg>
);
