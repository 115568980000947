import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '../fragments/user.fragment';

export const GET_USER_QUERY = gql`
  query getUser {
    getUser {
      ...user

      identities {
        id
        provider
      }
    }
  }

  ${USER_FRAGMENT}
`;
