import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment user on User {
    __typename
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    profilePicture
    role
    emailVerification

    profile {
      id
      createdAt
      updatedAt
      region
      skills
    }
    projects {
      id
      createdAt
      updatedAt
      name
      industry
      phase
    }

    onboardingResult
    onboardingData {
      answer
      question
    }
  }
`;
