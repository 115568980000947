import { useQuery } from '@apollo/client';
import {
  getUser,
  getUser_getUser,
} from '../../apollo/queries/__generated__/getUser';
import { GET_USER_QUERY } from '../../apollo/queries/getUser';
import { isAdmin } from '../utils/helpers';
import { getStoredAccessToken } from '../utils/authHelpers';

type UserProps = {
  gotToken: boolean;
  loading: boolean;
  user: getUser_getUser | undefined;
  isAdmin: boolean;
};

export const useUserData = () => {
  const gotToken = !!getStoredAccessToken();

  const { data, loading } = useQuery<getUser>(GET_USER_QUERY, {
    skip: !gotToken,
  });

  const userBag: UserProps = {
    loading: loading,
    isAdmin: isAdmin(data?.getUser),
    user: data?.getUser,
    gotToken,
  };

  return userBag;
};
