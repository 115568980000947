import React, { FC } from 'react';
import { LogoIcon } from '../../../components/Icon/Icons/LogoIcon';
import { Icon } from '../../../components/Icon/Icon';
import { useWhiteLabelContext } from '../../WhitelabeProvider';
import styled from 'styled-components';

export const ProjectLogo: FC = () => {
  const { loading, logo } = useWhiteLabelContext();

  return logo ? <StyledImg src={logo} alt="" /> : <Icon Component={LogoIcon} />;
};

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
