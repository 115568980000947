import { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { ProfilePicture } from '../../../components/profile/ProfilePicture';
import { useUserData } from '../../../hooks/useUserData';
import Link from 'next/link';
import { Routes } from '../../../enums/routes';

export const UserProfileSection: FC = (props) => {
  const { user } = useUserData();

  return (
    <ProfileSection>
      <UserName gutterBottom>
        {user.firstName} {user.lastName}
      </UserName>

      <Link href={Routes.UserProfileEdit} legacyBehavior>
        <a style={{ color: 'inherit' }}>
          <StyledProfilePicture
            profilePictureUrl={user.profilePicture}
            size={100}
          />
        </a>
      </Link>
    </ProfileSection>
  );
};

const StyledProfilePicture = styled(ProfilePicture)`
  margin-bottom: 1rem;
  cursor: pointer;
`;

const UserName = styled(Typography)`
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
`;

const ProfileSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-bottom: 32px;
`;
