import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@mui/material';

export const ScrollProgressBar: FC = () => {
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = (winScroll / height) * 100;

    setScrollTop(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  return <SLinearProgress variant="determinate" value={scrollTop} />;
};

const SLinearProgress = styled(LinearProgress)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
`;
