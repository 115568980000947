import { FC } from 'react';
import styled from 'styled-components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { squareSize } from '../../styles/helpers';

export type SizeOptions = 'small' | 'normal' | 'big';

const avatarSizes = {
  small: '1.5rem',
  normal: '100px',
  big: '6rem',
};

type Props = {
  size?: number;
  profilePictureUrl?: string;

  className?: string;
};

export const ProfilePicture: FC<Props> = (props) => {
  const { size, className, profilePictureUrl } = props;

  return (
    <ProfilePictureWrapper className={className} size={size}>
      {profilePictureUrl ? (
        <img src={profilePictureUrl} alt={profilePictureUrl} />
      ) : (
        <StyledAvatarIcon />
      )}
    </ProfilePictureWrapper>
  );
};

const ProfilePictureWrapper = styled.div<{ size?: number }>`
  position: relative;
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  flex-shrink: 0;

  ${(props) => props.size && squareSize(`${props.size}px`)}

  img {
    margin: auto;
    width: 100%;
    height: min-content;
  }
`;

export const StyledAvatarIcon = styled(AccountCircleIcon)`
  margin: auto;
  ${squareSize('100%')};
`;
