import { FC } from 'react';
import {
  BurgerButton,
  CircleBackground,
  BurgerBars,
} from './mobileMenuButton.styles';

type Props = {
  isMenuOpen: boolean;
  toggleIsMenuOpen: () => void;
};

export const MobileMenuButton: FC<Props> = ({
  isMenuOpen,
  toggleIsMenuOpen,
}) => (
  <BurgerButton onClick={toggleIsMenuOpen}>
    <CircleBackground isMenuOpen={isMenuOpen} />
    <BurgerBars isMenuOpen={isMenuOpen}>
      <div />
      <div />
      <div />
    </BurgerBars>
  </BurgerButton>
);
