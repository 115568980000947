import React from 'react';
import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <Typography>
            <Trans>Něco se pokazilo, zkuste prosím stránku obnovit</Trans>
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}
