import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const LogoutIcon: FC<IconSVGProps> = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 32 32"
  >
    <defs>
      <path id="path-1" d="M1.22583916e-15 0L32 0 32 32 0 32z" />
      <path
        id="path-3"
        d="M19.997 24.299v-1.331a.75.75 0 00-1.5 0v1.331a2.087 2.087 0 01-2.084 2.085H7.585A2.088 2.088 0 015.5 24.299V7.585c0-1.149.936-2.085 2.085-2.085h8.828c1.149 0 2.084.936 2.084 2.085v1.428a.75.75 0 001.5 0V7.585A3.59 3.59 0 0016.413 4H7.585A3.59 3.59 0 004 7.585v16.714a3.59 3.59 0 003.585 3.585h8.828a3.589 3.589 0 003.584-3.585m5.899-8.503c.092.087.094.156 0 .244l-.009.009c.01-.049.03-.095.03-.147 0-.038-.017-.071-.022-.107l.001.001zm-3.683-3.813c0 .198.079.396.234.543l2.784 2.64c-.022-.002-.042-.014-.064-.014H16.21a.75.75 0 000 1.5h8.957c.037 0 .069-.015.105-.021l-2.819 2.674a.751.751 0 001.032 1.089l3.443-3.265c.676-.642.676-1.78 0-2.422l-3.449-3.269a.75.75 0 00-1.266.545z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <mask fill="#fff">
        <use xlinkHref="#path-1" />
      </mask>
      <mask fill="#fff">
        <use xlinkHref="#path-3" />
      </mask>
      <use fill="#FFF" xlinkHref="#path-3" />
    </g>
  </svg>
);
